import React, { createContext, useEffect, useState, ReactNode } from "react";

import { FEATURE_FLAGS } from "../../feature-flags";

interface FeatureFlagsContextType {
    [key: string]: string | boolean;
}

export const FeatureFlagsContext = createContext<FeatureFlagsContextType>({});

interface FeatureFlagsProviderProps {
    children: ReactNode;
}

export const FeatureFlagsProvider = ({
    children,
}: FeatureFlagsProviderProps) => {
    const [featureFlags, setFeatureFlags] = useState<FeatureFlagsContextType>(
        Object.fromEntries(
            Object.keys(FEATURE_FLAGS).map((key) => [key, "control"]),
        ),
    );

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            // Fallback if feature flags don't load within the time limit
            if (loading) {
                setFeatureFlags((prevFlags) =>
                    Object.fromEntries(
                        Object.keys(prevFlags).map((key) => [key, "control"]),
                    ),
                );
                setLoading(false);
            }
        }, 3000); // 5s timeout for fallback

        const updateFlags = () => {
            setFeatureFlags(
                Object.fromEntries(
                    Object.entries(FEATURE_FLAGS).map(([key, posthogKey]) => [
                        key,
                        window.posthog.getFeatureFlag(posthogKey as string) ??
                            "control",
                    ]),
                ),
            );
            setLoading(false);
            clearTimeout(timeout);
        };

        const checkPosthog = () => {
            if (window.posthog?.__loaded) {
                window.posthog.onFeatureFlags(updateFlags);
            } else {
                setTimeout(checkPosthog, 500); // Retry every 500ms
            }
        };

        checkPosthog();

        return () => clearTimeout(timeout);
    }, [loading]);

    return (
        <FeatureFlagsContext.Provider value={featureFlags}>
            {children}
        </FeatureFlagsContext.Provider>
    );
};
