// Example:
//
// export const FEATURE_FLAGS = {
//     PRIMARY_CTA: "primary-cta"
// } as const;
//
// "PRIMARY_CTA" is the one to be used in the app, "primary-cta" is the feature flag key from posthog
//
// Use in a component like this:
// import React, {useContext} from "react";
// import { FeatureFlagsContext } from "./src/context/feature-flag-context";
// import { FeatureFlagsType } from "../../../feature-flags";
// const featureFlags = useContext(FeatureFlagsContext) as FeatureFlagsType;
// const primaryCTAFeatureFlag = featureFlags.PRIMARY_CTA;

export const FEATURE_FLAGS = {
} as const;

// No need to change
export type FeatureFlagsType = {
    [key in keyof typeof FEATURE_FLAGS]: string;
};