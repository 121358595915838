/** 
  ### [Note: PrismJS Theme]
  PrismJS doesn't support automatic theme switching (yet). Plus there are also
  not great light/dark theme pairs. 
  
  So as a workaround, we use a duotone theme that works reasonably well in both
  modes after we change the background color (in `prism-theme-overrides.css`).
*/
import "prism-themes/themes/prism-duotone-space.css";
import "styles/prism-theme-overrides.css";

import React from "react";
import { FeatureFlagsProvider } from "./src/context/feature-flag-context";
import { FEATURE_FLAGS } from "./feature-flags";

// Wrap Gatsby's root element with the FeatureFlagsProvider
export const wrapRootElement = ({ element }) => {
    if (Object.keys(FEATURE_FLAGS).length > 0) {
        return <FeatureFlagsProvider>{element}</FeatureFlagsProvider>;
    } else {
        return element;
    }
};

export const onRouteUpdate = () => {
    if (window.posthog) {
        // Wait for feature flags to load before capturing pageview
        window.posthog.onFeatureFlags(() => {
            let updatedFlags = {};

            if (Object.keys(FEATURE_FLAGS).length > 0) {
                updatedFlags = Object.fromEntries(
                    Object.entries(FEATURE_FLAGS).map(([key, posthogKey]) => [
                        key,
                        window.posthog.getFeatureFlag(posthogKey) ?? "control",
                    ]),
                );
            }

            // Use "control" as fallback if PostHog feature flags fail
            window.posthog.capture("$pageview", {
                url: window.location.href,
                featureFlags: updatedFlags,
            });
        });
    }
};
